.header {
    position: sticky;
    background-color: #131921;
    width: 100%;
    top:0;
    z-index:100;
    height: 60px;
    display: flex;
    align-items: center;

}

.header__logo {
    object-fit: contain;
    width: 100px;
    margin:0 20px;
    margin-top:18px;
}

.header__searchInput {
    height: 12px;
    padding: 10px;
    border: none;
    width: 100%;
}

.header__search {
display: flex;
flex:1;

}

.header__searchIcon {
    background-color: #cd9042;
    padding: 5px;
    height: 22px !important;
}

.header__nav {
    display:flex;
    justify-content: space-evenly;
}

.header__link {
    color: white;
    text-decoration: none;
}

.header__option {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;
}

.header__optionLineOne {
   font-size: 10px;
}

.header__optionLineTwo {
    font-size: 13px;
    font-weight: 800;
}

.header__optionBasket {
    display:flex;
    align-items: center;
}

.header__optionBasketCount {
    margin-left: 10px;
    margin-right: 10px;
}